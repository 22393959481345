import React from 'react'
import { FolderInterface } from '../../features/Drives/redux/drivesSlice'
import { Card, Popover } from 'antd'
import './DriveCard.scss'
import { MdOutlineFolderShared } from 'react-icons/md'
import { AiOutlineFolder } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import { useDrivesContext } from '../../features/Drives/DrivesContext'
import DriveRightsIcon from './DriveRightsIcon'

interface Props {
  driveRootFolder: FolderInterface
}

/**
 *
 */
function DriveCard({ driveRootFolder }: Props) {
  const { t } = useTranslation('drives')
  const { fetchOrSelectFolder } = useDrivesContext()

  /**
   *
   */
  function onSelectDrive() {
    fetchOrSelectFolder(driveRootFolder)
  }

  function onOpenFolder(folder: FolderInterface) {
    fetchOrSelectFolder(folder)
  }

  const popoverContent = (
    <div className="child-folders-popover">
      {driveRootFolder.childrenFolders.length > 0 ? (
        driveRootFolder.childrenFolders.map((folder) => (
          <div
            key={folder.id}
            className="child-folder"
            onClick={() => onOpenFolder(folder)}
          >
            <AiOutlineFolder size="1.5em" />
            <span>{folder.name}</span>
          </div>
        ))
      ) : (
        <span>{t('No subfolders')}</span>
      )}
    </div>
  )

  return (
    <Popover title={t('Subfolders')} content={popoverContent}>
      <Card className="DriveCard" hoverable onDoubleClick={onSelectDrive}>
        <div className="container">
          <div className="title-container">
            <div className="icon-container">
              <MdOutlineFolderShared size="1.6em" />
            </div>
            <h4 className="title">{driveRootFolder.name}</h4>
          </div>
          <DriveRightsIcon driveId={driveRootFolder.driveId} />
        </div>
      </Card>
    </Popover>
  )
}

export default DriveCard
