import React, { useEffect, useRef } from 'react'
import { Button, Divider, Form, Input, InputRef, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import {
  createFolder,
  fetchDriveRights,
  idleCreateFolderStatus,
  idleDeleteFolderStatus,
  setFolderToDelete,
  setOpenCreateFolder,
} from '../../../features/Drives/redux/drivesSlice'
import { useAttemptsListener } from 'auxasphere-react-kit'
import { TbListTree } from 'react-icons/tb'
import DeleteFolderModal from '../DeleteFolderModal'
import _ from 'lodash'
import DrivesBreadcrumb from './DrivesBreadcrumb'
import { useDriveLoader } from '../../../utils/hooks/UseDriveLoader'
import { useToastContext } from '../../Toast/ToastContext'

function DrivesHeader() {
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation('drives')
  const auth = useSelector((state: RootState) => state.auth)

  const { creatorEmail, selectedDriveFolder } = useDriveLoader()
  const openCreateFolderModal = useSelector(
    (state: RootState) => state.drive.openCreateFolder,
  )
  const [folderForm] = Form.useForm()
  const inputRef = useRef<InputRef>(null)

  const createFolderStatus = useSelector(
    (state: RootState) => state.drive.createFolderStatus,
  )

  const deleteFolderStatus = useSelector(
    (state: RootState) => state.drive.deleteFolderStatus,
  )
  const { ToastOpen } = useToastContext()

  /**
   * Fetch drive rights
   */
  useEffect(() => {
    if (selectedDriveFolder?.driveId && auth.email != creatorEmail) {
      dispatch(
        fetchDriveRights({
          driveId: selectedDriveFolder.driveId as string,
        }),
      )
    }
  }, [selectedDriveFolder?.driveId])

  useAttemptsListener([
    [
      createFolderStatus,
      {
        success: () => {
          ToastOpen({
            message: t('Folder was successfully created.'),
            type: 'success',
          })
          onClose()
        },
        NOT_ENOUGH_FOLDER_RIGHT: () => {
          ToastOpen({
            message: t(
              'You do not have the necessary rights to create folders in this project.',
            ),
            type: 'error',
          })
          onClose()
        },
        FOLDER_ALREADY_EXIST: () => {
          ToastOpen({
            message: t('Unable to create the folder because this name already exists.'),
            type: 'error',
          })
        },
        FETCH_ERROR: () => {
          ToastOpen({
            message: t('The drive service is not available at the moment.'),
            type: 'error',
            duration: 50,
          })
        },
        unknown_error: () => {
          ToastOpen({
            message: t('Error creating folder.'),
            type: 'error',
          })
        },
      },
      () => dispatch(idleCreateFolderStatus()),
    ],
    [
      deleteFolderStatus,
      {
        success: () => {
          ToastOpen({
            message: t('Folder was successfully removed.'),
            type: 'success',
          })
        },
        NOT_ENOUGH_FOLDER_RIGHT: () => {
          ToastOpen({
            message: t(
              'You do not have the necessary rights to delete folders in this project.',
            ),
            type: 'error',
          })
        },
        NO_ADMIN_RIGHTS: () => {
          ToastOpen({
            message: t("You can't delete a root folder."),
            type: 'error',
          })
        },
        FETCH_ERROR: () => {
          ToastOpen({
            message: t('The drive service is not available at the moment.'),
            type: 'error',
            duration: 50,
          })
        },
        unknown_error: () => {
          ToastOpen({
            message: t('Error deleting folder.'),
            type: 'error',
          })
        },
      },
      () => {
        dispatch(setFolderToDelete(undefined))
        dispatch(idleDeleteFolderStatus())
      },
    ],
  ])

  /**
   *
   */
  function onCreateFolder() {
    if (selectedDriveFolder) {
      dispatch(
        createFolder({
          parentId: selectedDriveFolder.id,
          name: folderForm.getFieldsValue().name,
          driveId: selectedDriveFolder.driveId,
        }),
      )
    }
  }

  /**
   *
   */
  function onClose() {
    dispatch(setOpenCreateFolder(false))
    folderForm.resetFields()
  }

  return (
    <>
      {selectedDriveFolder && (
        <>
          <div className="layout-header-bottom drive-header">
            <h4 className="layout-list-header">
              <div className="tree-mobile-container">
                <DrivesBreadcrumb />
              </div>
            </h4>
          </div>
          <Divider />
        </>
      )}

      <Modal
        centered
        open={openCreateFolderModal}
        title={t('Create folder')}
        onOk={folderForm.submit}
        onCancel={onClose}
        okText={t('Create')}
        cancelText={t('Cancel', { ns: 'common' })}
        afterOpenChange={(open) => open && inputRef.current?.focus()}
      >
        <div className="group-container">
          <Form
            name="basic"
            layout="vertical"
            autoComplete="off"
            form={folderForm}
            onFinish={onCreateFolder}
          >
            <Form.Item
              className="mb-0"
              // style={{ marginBottom: '0px' }}
              name="name"
              label={t('Folder name')}
              rules={[{ required: true, message: t('Please input folder name') }]}
            >
              <Input ref={inputRef} />
            </Form.Item>
          </Form>
        </div>
      </Modal>

      <DeleteFolderModal />
    </>
  )
}

export default DrivesHeader
