import React from 'react'
import { Tree } from 'antd'
import type { DirectoryTreeProps, EventDataNode } from 'antd/es/tree'
import { useDispatch, useSelector } from 'react-redux'
import { MdOutlineFolderShared } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { FolderTreeData } from './DrivesTree'
import {
  fetchFolderById,
  FolderInterface,
} from '../../../../features/Drives/redux/drivesSlice'
import { AppDispatch, RootState } from '../../../../store'
import { findFolderById } from '../../DrivesService'
import DriveRightsIcon from '../../DriveRightsIcon'

const { DirectoryTree } = Tree

interface Props {
  folder: FolderTreeData
  drivesFolders: FolderInterface[]
  selectedFolder: FolderInterface | undefined
  onSelectFolder: (folder: FolderInterface) => void
  expandedKeys: React.Key[]
  setExpandedKeys: (key: React.Key[]) => void
  manageDrive: boolean
}

function DriveTreeItem({
  folder,
  drivesFolders,
  manageDrive,
  selectedFolder,
  onSelectFolder,
  expandedKeys,
  setExpandedKeys,
}: Props) {
  const { t } = useTranslation('drives')
  const dispatch = useDispatch<AppDispatch>()
  const email = useSelector((state: RootState) => state.auth.email)
  const groups = useSelector((state: RootState) => state.auth.groups)
  const drives = useSelector((state: RootState) => state.drive.drives)

  /**
   *
   * @param keys
   * @param info
   */
  const onExpand: DirectoryTreeProps['onExpand'] = (keys, info) => {
    const nodeInfo = info.node as EventDataNode<FolderInterface>
    if (nodeInfo.active == false) {
      const folder = findFolderById(drivesFolders, nodeInfo, nodeInfo.key)
      if (folder) {
        if (
          folder.childrenFolders &&
          folder.childrenFolders.length > 0 &&
          typeof folder.childrenFolders[0] === 'string'
        ) {
          dispatch(fetchFolderById({ id: folder.id, driveId: folder.driveId }))
        }
      }
      setExpandedKeys(keys)
    }
  }

  /**
   *
   * @param keys
   * @param info
   */
  const onSelect: DirectoryTreeProps['onSelect'] = (keys, info) => {
    const nodeInfo = info.node as EventDataNode<FolderInterface>

    const folder = findFolderById(drivesFolders, nodeInfo, nodeInfo.key)
    if (folder) {
      onSelectFolder(folder)
    }
  }

  return (
    <DirectoryTree
      onExpand={onExpand}
      onSelect={onSelect}
      treeData={[
        {
          ...folder,
          icon: (
            <MdOutlineFolderShared
              className="DrivesMenu-project-title-icon"
              size="1.2em"
            />
          ),
          title: (
            <span className="DrivesMenu-project-title-container">
              <span className="title-folder">{folder.title}</span>
              {manageDrive && (
                <span className="group-icon">
                  <DriveRightsIcon driveId={folder.driveId} />
                </span>
              )}
            </span>
          ),
        },
      ]}
      selectedKeys={selectedFolder ? [selectedFolder?.id] : []}
      expandedKeys={expandedKeys}
    />
  )
}

export default DriveTreeItem
