import React from 'react'
import { Button, List, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import './DriveUsers.scss'
import {
  DriveUserParticipant,
  DriveRole,
  setParticipants,
} from '../../../features/Drives/redux/drivesSlice'
import VirtualList from 'rc-virtual-list'
import { IoTrashOutline } from 'react-icons/io5'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import { useDriveLoader } from '../../../utils/hooks/UseDriveLoader'

interface Props {
  newDrive: boolean
}

function DriveUsers({ newDrive }: Props) {
  const { t } = useTranslation('drives')
  const dispatch = useDispatch<AppDispatch>()
  const participants = useSelector(
    (state: RootState) => state.drive.driveModifyForm.participants,
  )
  const {
    modifiedDriveCreatorEmail: creatorEmail,
    modifiedDriveCreatorFullName: creatorFullName,
    modifiedDriveAdminRights: adminRights,
  } = useDriveLoader()
  const auth = useSelector((state: RootState) => state.auth)
  const isAdmin = adminRights || newDrive

  function renderSelectValue(participant: DriveUserParticipant) {
    switch (true) {
      case participant.adminRights:
        return DriveRole.ADMIN
      case participant.folderRights:
        return DriveRole.FOLDERS_FILES
      case participant.fileRights:
        return DriveRole.FILES
      default:
        return DriveRole.READ_ONLY
    }
  }

  /**
   *
   */
  function removeDriveParticipant(participantToRemove: DriveUserParticipant) {
    const index = participants.findIndex(
      (participant) => participant === participantToRemove,
    )

    if (index !== -1) {
      const updatedParticipants = [...participants]
      updatedParticipants.splice(index, 1)
      dispatch(setParticipants(updatedParticipants))
    }
  }

  /**
   *
   * @param email
   * @param newRole
   */
  function changeParticipantRole(
    participantToChange: DriveUserParticipant,
    newRole: DriveRole,
  ) {
    const index = participants.findIndex(
      (participant) => participant === participantToChange,
    )
    const updatedParticipants = [...participants]
    switch (newRole) {
      case DriveRole.ADMIN:
        updatedParticipants[index] = {
          ...participantToChange,
          adminRights: true,
          folderRights: true,
          fileRights: true,
        }
        break

      case DriveRole.FOLDERS_FILES:
        updatedParticipants[index] = {
          ...participantToChange,
          adminRights: false,
          folderRights: true,
          fileRights: true,
        }
        break

      case DriveRole.FILES:
        updatedParticipants[index] = {
          ...participantToChange,
          adminRights: false,
          folderRights: false,
          fileRights: true,
        }
        break

      default:
        updatedParticipants[index] = {
          ...participantToChange,
          adminRights: false,
          folderRights: false,
          fileRights: false,
        }
    }
    dispatch(setParticipants(updatedParticipants))
  }

  const adminAsParticipant: DriveUserParticipant = {
    email: (newDrive ? auth.email : creatorEmail) || '',
    fullName: (newDrive ? auth.firstName + ' ' + auth.lastName : creatorFullName) || '',
    adminRights: true,
    folderRights: true,
    fileRights: true,
  }

  const disableParticipantItem = (email: string) => {
    return (
      (!isAdmin && !newDrive) ||
      (email === creatorEmail && !newDrive) ||
      (email === auth.email && newDrive)
    )
  }

  const isDriveCreator = (email: string) => {
    return email === creatorEmail || (email === auth.email && newDrive)
  }

  return (
    <div className="DriveUsers">
      <List>
        <VirtualList
          data={[adminAsParticipant, ...participants]}
          itemKey="email"
          className="list"
        >
          {(participant: DriveUserParticipant) => (
            <List.Item key={participant.email}>
              <List.Item.Meta
                title={participant.fullName}
                description={participant.email}
              />
              <div className="d-flex d-flex-center d-flex-middle g-0_5rem">
                <Select
                  defaultValue={renderSelectValue(participant)}
                  onChange={(value) => changeParticipantRole(participant, value)}
                  disabled={disableParticipantItem(participant.email)}
                >
                  <Select.Option value={DriveRole.READ_ONLY}>
                    {t(DriveRole.READ_ONLY)}
                  </Select.Option>
                  <Select.Option value={DriveRole.FILES}>
                    {t(DriveRole.FILES)}
                  </Select.Option>
                  <Select.Option value={DriveRole.FOLDERS_FILES}>
                    {t(DriveRole.FOLDERS_FILES)}
                  </Select.Option>
                  <Select.Option value={DriveRole.ADMIN}>
                    {t(DriveRole.ADMIN)}
                  </Select.Option>
                </Select>
                {!disableParticipantItem(participant.email) && (
                  <Button
                    className="btn-danger-border"
                    onClick={() => removeDriveParticipant(participant)}
                  >
                    <IoTrashOutline className="error-color" />
                  </Button>
                )}
                {isDriveCreator(participant.email) && participant.email == auth.email && (
                  <Button className="btn-danger-border hidden">
                    <IoTrashOutline />
                  </Button>
                )}
              </div>
            </List.Item>
          )}
        </VirtualList>
      </List>
    </div>
  )
}

export default DriveUsers
