import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RoomToModify } from './MeetingsSlice'
import {
  fetchCreatePeriodicRoom,
  fetchCreateRoom,
  fetchUpdatePlanRoom,
  fetchUpdateRoom,
} from '../../utils/MeetingUtils'
import {
  RoomFile,
  RoomInterface,
  RoomParticipant,
  RoomParticipantRole,
} from '../room/RoomInterface'
import { FetchStatus } from '../../utils/FetchStatus'
import { RootState } from '../../store'
import dayjs from 'dayjs'

export type Frequency = 'day' | 'week' | 'month' | 'year'
export type Days =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday'

export interface Periodicity {
  dtstart: Date
  freq: Frequency
  interval: number
  dstart?: number
  byweekday?: Days[]
  bymonth?: number[]
  bymonthday?: number[]
  bysetpos?: number[]
  until?: Date
  count?: number
}

interface State {
  roomToCreate?: RoomToModify
  createRoomStatus: FetchStatus
  updateRoomStatus: FetchStatus
  createPeriodicRoomStatus: FetchStatus
  updatePlanRoomStatus: FetchStatus
  plannedDates?: number[] // Date can't be serialized, so we store the timestamp
  duplicateMeeting: RoomInterface
}

export const initialState: State = {
  roomToCreate: {},
  createRoomStatus: 'idle',
  updateRoomStatus: 'idle',
  createPeriodicRoomStatus: 'idle',
  updatePlanRoomStatus: 'idle',
  plannedDates: [],
  duplicateMeeting: {},
}

/**
 * Initialize a new room
 */
export const createRoom = createAsyncThunk(
  'newRoom/createRoomStatus',
  async (_, { getState, dispatch }) => {
    const { auth } = getState() as { auth: { jwt: string } }
    const { newMeeting } = getState() as RootState

    const createdRoom = await fetchCreateRoom(auth.jwt)

    if (newMeeting.duplicateMeeting.name) {
      const duplicateMeeting = {
        ...newMeeting.duplicateMeeting,
        files: [],
        id: createdRoom.id,
        decrypt_key: createdRoom.decrypt_key,
      }
      dispatch(setNewMeeting(duplicateMeeting))
    } else {
      dispatch(setNewMeeting(createdRoom))
    }
  },
)

/**
 * Create new periodic room
 */
export const createPeriodicRoom = createAsyncThunk(
  'newRoom/createPeriodicRoomStatus',
  async (payload: RoomToModify, { getState }) => {
    const { auth } = getState() as { auth: { jwt: string } }
    await fetchCreatePeriodicRoom(auth.jwt, payload)
  },
)

/**
 * Create the new room
 */
export const updateRoom = createAsyncThunk(
  'newRoom/updateRoomStatus',
  async (payload: RoomToModify, { getState }) => {
    const { auth } = getState() as { auth: { jwt: string } }
    await fetchUpdateRoom(auth.jwt, payload)
  },
)

/**
 * Create room with planner.
 */
export const updatePlanRoom = createAsyncThunk(
  'newRooms/updatePlanRoomStatus',
  async (
    {
      room: {
        id,
        name,
        color,
        type,
        reference,
        duration,
        creatorNote,
        participants,
        invited,
        unconfirmedFiles,
        unconfirmedFileDeletions,
        periodicity,
      },
      availableDates,
    }: { room: RoomToModify; availableDates: number[] },
    { getState },
  ) => {
    const { auth } = getState() as { auth: { jwt: string } }
    await fetchUpdatePlanRoom(
      auth.jwt,
      {
        id,
        name,
        color,
        type,
        reference,
        duration,
        creatorNote,
        participants,
        invited,
        unconfirmedFiles,
        unconfirmedFileDeletions,
        periodicity,
      },
      availableDates.map((date) => new Date(date).toISOString()),
    )
  },
)

const newMeetingSlice = createSlice({
  name: 'newMeeting',
  initialState,
  reducers: {
    setNewMeeting: (state, { payload }: PayloadAction<RoomToModify | undefined>) => {
      state.roomToCreate = payload
      state.plannedDates = []
    },
    addDate: (state, { payload }: PayloadAction<string>) => {
      if (!state.plannedDates) {
        state.plannedDates = []
      }
      const element = new Date(payload)
      state.plannedDates.push(element.getTime())
    },
    deleteDate(state, { payload }: PayloadAction<number>) {
      if (state.plannedDates) {
        state.plannedDates = state.plannedDates.filter((d) => d !== payload)
      }
    },
    addParticipant: (state, { payload }: PayloadAction<RoomParticipant>) => {
      if (state.roomToCreate) {
        if (!state.roomToCreate.participants) {
          state.roomToCreate.participants = []
        }
        const newParticipant: RoomParticipant = {
          email: payload.email.trim().toLocaleLowerCase(),
          role: payload.role,
          color: payload.color,
        }

        const existingParticipantIndex = state.roomToCreate.participants.findIndex(
          (p) => p.email === newParticipant.email,
        )
        if (existingParticipantIndex >= 0) {
          state.roomToCreate.participants.splice(
            existingParticipantIndex,
            1,
            newParticipant,
          )
        } else {
          state.roomToCreate.participants.push(payload)
        }
      }
    },
    deleteParticipant: (state, { payload }: PayloadAction<string>) => {
      if (state.roomToCreate) {
        if (!state.roomToCreate.participants) {
          state.roomToCreate.participants = []
        }
        state.roomToCreate.participants = state.roomToCreate.participants.filter(
          (p) => p.email !== payload,
        )
      }
    },
    updateParticipantRole: (state, { payload }: PayloadAction<string>) => {
      if (state.roomToCreate?.participants) {
        const [participantMail, newRole] = payload.split(' ')
        const index = state.roomToCreate.participants.findIndex(
          (participant) => participant.email === participantMail,
        )
        state.roomToCreate.participants[index].role = newRole as RoomParticipantRole
      }
    },
    addInvited: (state, { payload }: PayloadAction<RoomParticipant>) => {
      if (state.roomToCreate) {
        if (!state.roomToCreate.invited) {
          state.roomToCreate.invited = []
        }
        const newParticipant: RoomParticipant = {
          email: payload.email.trim().toLocaleLowerCase(),
          role: payload.role,
        }

        const existingInvitedIndex = state.roomToCreate.invited.findIndex(
          (p) => p.email === newParticipant.email,
        )
        if (existingInvitedIndex >= 0) {
          state.roomToCreate.invited.splice(existingInvitedIndex, 1, newParticipant)
        } else {
          state.roomToCreate.invited.push(payload)
        }
      }
    },
    deleteInvited: (state, { payload }: PayloadAction<string>) => {
      if (state.roomToCreate) {
        if (!state.roomToCreate.invited) {
          state.roomToCreate.invited = []
        }
        state.roomToCreate.invited = state.roomToCreate.invited.filter(
          (p) => p.email !== payload,
        )
      }
    },
    updateInvitedRole: (state, { payload }: PayloadAction<string>) => {
      if (state.roomToCreate?.invited) {
        const [invitedMail, newRole] = payload.split(' ')
        const index = state.roomToCreate.invited.findIndex(
          (invited) => invited.email === invitedMail,
        )
        state.roomToCreate.invited[index].role = newRole as RoomParticipantRole
      }
    },
    idleCreateRoomStatus: (state) => {
      state.createRoomStatus = 'idle'
    },
    idleUpdateRoomStatus: (state) => {
      state.updateRoomStatus = 'idle'
    },
    idleCreatePeriodicRoomStatus: (state) => {
      state.createPeriodicRoomStatus = 'idle'
    },
    idleUpdatePlanRoomStatus: (state) => {
      state.updatePlanRoomStatus = 'idle'
    },
    addUnconfirmedFile: (
      state,
      { payload }: PayloadAction<{ roomId: string; file: RoomFile }>,
    ) => {
      if (state.roomToCreate?.id === payload.roomId) {
        if (!state.roomToCreate.unconfirmedFiles) {
          state.roomToCreate.unconfirmedFiles = []
        }
        state.roomToCreate.unconfirmedFiles.push(payload.file)
      }
    },
    removeUnconfirmedFile: (
      state,
      { payload }: PayloadAction<{ roomId: string; fileUid: string }>,
    ) => {
      if (state.roomToCreate?.id === payload.roomId) {
        if (!state.roomToCreate.unconfirmedFileDeletions) {
          state.roomToCreate.unconfirmedFileDeletions = []
        }
        state.roomToCreate.unconfirmedFileDeletions?.push(payload.fileUid)
      }
    },
    setDuplicateMeeting: (state, { payload }: PayloadAction<RoomInterface>) => {
      state.duplicateMeeting = payload
    },
    clearRoomDuplicate: (state) => {
      state.duplicateMeeting = {}
    },
  },

  extraReducers: {
    [createRoom.pending.type]: (state, action) => {
      state.createRoomStatus = 'loading'
    },
    [createRoom.fulfilled.type]: (state, action) => {
      state.createRoomStatus = 'success'
    },
    [createRoom.rejected.type]: (state, action) => {
      state.createRoomStatus = 'error'
    },
    [updateRoom.pending.type]: (state, action) => {
      state.updateRoomStatus = 'loading'
    },
    [updateRoom.fulfilled.type]: (state, action) => {
      state.updateRoomStatus = 'success'
    },
    [updateRoom.rejected.type]: (state, action) => {
      state.updateRoomStatus = 'error'
    },
    [updatePlanRoom.pending.type]: (state, action) => {
      state.updatePlanRoomStatus = 'loading'
    },
    [updatePlanRoom.fulfilled.type]: (state, action) => {
      state.updatePlanRoomStatus = 'success'
    },
    [updatePlanRoom.rejected.type]: (state, action) => {
      state.updatePlanRoomStatus = 'error'
    },
    [createPeriodicRoom.pending.type]: (state, action) => {
      state.createPeriodicRoomStatus = 'loading'
    },
    [createPeriodicRoom.fulfilled.type]: (state, action) => {
      state.createPeriodicRoomStatus = 'success'
    },
    [createPeriodicRoom.rejected.type]: (state, action) => {
      state.createPeriodicRoomStatus = 'error'
    },
  },
})

export default newMeetingSlice.reducer
export const {
  setNewMeeting,
  addDate,
  deleteDate,
  addParticipant,
  deleteParticipant,
  updateParticipantRole,
  addInvited,
  deleteInvited,
  updateInvitedRole,
  idleCreateRoomStatus,
  idleUpdateRoomStatus,
  idleCreatePeriodicRoomStatus,
  idleUpdatePlanRoomStatus,
  addUnconfirmedFile,
  removeUnconfirmedFile,
  setDuplicateMeeting,
  clearRoomDuplicate,
} = newMeetingSlice.actions
