import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import { Trans, useTranslation } from 'react-i18next'
import { useToastContext } from '../../Toast/ToastContext'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import DrivesTree from './DriveTree/DrivesTree'
import {
  FileInterface,
  FolderInterface,
  moveDriveContent,
  setSelectedFolderDestination,
} from '../../../features/Drives/redux/drivesSlice'
import { useDriveLoader } from '../../../utils/hooks/UseDriveLoader'

interface Props {
  openMoveFileModal: boolean
  setOpenMoveFileModal: React.Dispatch<React.SetStateAction<boolean>>
  file: FileInterface
}

function MoveDriveItem({ openMoveFileModal, setOpenMoveFileModal, file }: Props) {
  const { t } = useTranslation('drives')
  const dispatch = useDispatch<AppDispatch>()
  const { ToastOpen } = useToastContext()
  const { selectedDriveFolder } = useDriveLoader()
  const expandedKeys = useSelector((state: RootState) => state.drive.expandedFolderKeys)
  const [localExpandedKeys, setLocalExpandedKeys] = useState<React.Key[]>(expandedKeys)
  const drivesFolders = useSelector(
    (state: RootState) =>
      state.drive.drives?.find((drive) => drive.id === selectedDriveFolder?.driveId)
        ?.rootFolder,
  )
  const selectedFolderDestination = useSelector(
    (state: RootState) => state.drive.selectedFolderDestination,
  )

  useEffect(() => {
    setLocalExpandedKeys(expandedKeys)
  }, [expandedKeys])

  function onCancelMoveFile() {
    dispatch(setSelectedFolderDestination({} as FolderInterface))
    setOpenMoveFileModal(false)
  }

  /**
   * Modal: Moves the selected file to the selected folder
   */
  function onMoveFile() {
    if (selectedFolderDestination && selectedDriveFolder) {
      if (selectedDriveFolder.id !== selectedFolderDestination.id) {
        dispatch(
          moveDriveContent({
            contentToMove: [file],
            folderId: selectedDriveFolder.id,
            targetedFolderId: selectedFolderDestination.id,
            driveId: selectedFolderDestination.driveId,
          }),
        )
      } else {
        ToastOpen({
          message: t('Error moving file.'),
          type: 'error',
        })
      }
    }

    onCancelMoveFile()
  }

  return (
    <>
      <Modal
        centered
        open={openMoveFileModal}
        title={`${t('Move file')} - ${drivesFolders?.name.toUpperCase()}`}
        onCancel={onCancelMoveFile}
        onOk={onMoveFile}
        okText={t('Move', { ns: 'common' })}
        cancelText={t('Cancel', { ns: 'common' })}
        okButtonProps={{
          disabled: !selectedFolderDestination?.id,
          className: !selectedFolderDestination?.id ? 'btn-disabled' : '',
        }}
      >
        <p>
          <Trans
            ns="drives"
            i18nKey="DRIVE_MOVE_ITEM_ASKING"
            values={{
              file: file.name,
              folder: selectedFolderDestination ? selectedFolderDestination.name : '',
            }}
            components={{ bold: <strong /> }}
          />
        </p>
        <div className="Drives-main-container h-30rem-i">
          <DrivesTree
            drivesFolders={drivesFolders ? [drivesFolders] : []}
            selectedFolder={selectedFolderDestination}
            onSelectFolder={(folder) => dispatch(setSelectedFolderDestination(folder))}
            expandedKeys={localExpandedKeys}
            setExpandedKeys={setLocalExpandedKeys}
          />
        </div>
        <br />
      </Modal>
    </>
  )
}

export default MoveDriveItem
