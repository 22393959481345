import React, { DragEvent, MouseEvent, useState } from 'react'
import {
  FolderInterface,
  moveDriveContent,
  setDroppedFilesLoading,
  setFolderToDelete,
  setFolderToRename,
  setSelectedFilesFolders,
  setTargetedFolder,
} from '../../../features/Drives/redux/drivesSlice'
import {
  AiOutlineEdit,
  AiOutlineEye,
  AiOutlineFolder,
  AiOutlineMinus,
} from 'react-icons/ai'
import { Button, Dropdown, FormInstance, Tooltip } from 'antd'
import { localFormatDate, truncateText } from '../../../utils/Utils'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { MenuProps } from 'antd/lib'
import { IoTrashOutline } from 'react-icons/io5'
import { useTranslation } from 'react-i18next'
import { useDrivesContext } from '../../../features/Drives/DrivesContext'
import { compareFilesFolders } from './DriveListContent'
import { useDriveLoader } from '../../../utils/hooks/UseDriveLoader'
import DriveDateTooltip from './DriveDateTooltip'
import { ItemType } from 'antd/es/menu/interface'

interface Props {
  folder: FolderInterface
  handleRowClick: (select: FolderInterface, event: MouseEvent) => void
  handleRightClick: (event: MouseEvent, itemsFilesOrFolders?: ItemType[]) => void
  renameFolderForm: FormInstance
  handleDragStart: (e: DragEvent<HTMLDivElement>) => void
  handleDragEnd: () => void
}

function FolderRow({
  folder,
  handleRowClick,
  handleRightClick,
  renameFolderForm,
  handleDragStart,
  handleDragEnd,
}: Props) {
  const { t } = useTranslation('drives')
  const dispatch = useDispatch<AppDispatch>()
  const { fetchOrSelectFolder } = useDrivesContext()

  const { folderRights, selectedDriveFolder, selectedFilesFolders } = useDriveLoader()

  const toolTipMaxCharacters = 100

  const [isDraggingOver, setIsDraggingOver] = useState(false)
  const currentlyDragging = useSelector((state: RootState) => state.drive.draggingOver)

  const unSelectFolder = (e: MouseEvent) => {
    const isLeavingList =
      !e.relatedTarget || !e.currentTarget.contains(e.relatedTarget as Node)

    if (isLeavingList) {
      setIsDraggingOver(false)
    }

    if (selectedDriveFolder) {
      dispatch(setTargetedFolder({ folder: selectedDriveFolder }))
    }
  }

  const handleDragEnter = (e: any) => {
    e.preventDefault()
    if (!selectedFilesFolders.find((item) => compareFilesFolders(item, folder))) {
      setIsDraggingOver(true)
      dispatch(setTargetedFolder({ folder }))
    } else {
      unSelectFolder(e)
    }
  }

  const handleDragLeave = (e: MouseEvent) => {
    e.preventDefault()
    unSelectFolder(e)
  }

  const handleDrop = (e: MouseEvent) => {
    e.preventDefault()
    setIsDraggingOver(false)

    if (selectedDriveFolder && selectedFilesFolders.length > 0) {
      const isTargetedFolderInSelectedFiles = selectedFilesFolders.find((item) => {
        if ('id' in item) {
          return item.id === folder.id
        }
        return false
      })

      if (!isTargetedFolderInSelectedFiles) {
        dispatch(setDroppedFilesLoading(true))
        dispatch(
          moveDriveContent({
            contentToMove: selectedFilesFolders,
            folderId: selectedDriveFolder.id,
            targetedFolderId: folder.id,
            driveId: folder.driveId,
          }),
        )
      }
    }
  }

  /**
   * Sets up the modal to rename the folder.
   *
   * @param event - The click event.
   * @param document - The folder to be renamed.
   */
  function setRenameFolder(document: FolderInterface) {
    if (selectedDriveFolder && document) {
      renameFolderForm.setFieldValue('name', document.name)
      dispatch(setFolderToRename({ folder: document }))
    }
  }

  /**
   * Handles the click event for deleting a folder.
   *
   * @param event - The click event.
   * @param document - The folder to be deleted.
   */
  function onDeleteFolder(document?: FolderInterface) {
    console.log('document:', document)
    if (document) {
      dispatch(
        setFolderToDelete({
          folder: document,
          mainFolder: false,
        }),
      )
    }
  }

  const handleMouseDown = (event: MouseEvent) => {
    if (!selectedFilesFolders.includes(folder) && !event.shiftKey && !event.ctrlKey) {
      dispatch(setSelectedFilesFolders([folder]))
    }
  }

  const folderItems: MenuProps['items'] = [
    {
      label: (
        <span className="d-flex d-flex-middle d-flex-center g-0_5rem">
          <AiOutlineEye size="1.2em" />
          {t('Open')}
        </span>
      ),
      onClick: () => fetchOrSelectFolder(folder),
      key: `open_folder_${folder.id}`,
    },
    {
      label: (
        <span className="d-flex d-flex-middle d-flex-center g-0_5rem">
          <AiOutlineEdit size="1.2em" />
          {t('Rename')}
        </span>
      ),
      onClick: () => setRenameFolder(folder),
      key: `rename_folder_${folder.id}`,
    },
    {
      type: 'divider',
    },
    {
      label: (
        <span className="d-flex d-flex-middle d-flex-center g-0_5rem error-color">
          <IoTrashOutline size="1.2em" />
          {t('Delete')}
        </span>
      ),
      onClick: () => onDeleteFolder(folder),
      className: 'delete-btn',
      key: `delete_folder_${folder.id}`,
    },
  ]

  const folderItemsPerDefault: MenuProps['items'] = [
    {
      label: (
        <span className="d-flex d-flex-middle d-flex-center g-0_5rem">
          <AiOutlineEye size="1.2em" />
          {t('Open')}
        </span>
      ),
      onClick: () => fetchOrSelectFolder(folder),
      key: `open_folder_${folder.id}`,
    },
  ]

  const isSelected = selectedFilesFolders.some((item) =>
    compareFilesFolders(item, folder),
  )

  return (
    <div
      className={`DriveListContent-table-row ${
        isSelected ? 'DriveListContent-table-row-selected' : ''
      } ${isDraggingOver ? 'folder-drag-over' : ''} ${
        currentlyDragging && isSelected ? 'file-dragging' : ''
      }`}
      onDoubleClick={() => fetchOrSelectFolder(folder)}
      onClick={(e) => handleRowClick(folder, e)}
      onContextMenu={(e) => {
        handleRightClick(e, folderRights ? folderItems : folderItemsPerDefault)
      }}
      draggable
      onDragStart={(e) => handleDragStart(e)}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onMouseDown={(e) => handleMouseDown(e)}
    >
      <div className="DriveListContent-table-name-column">
        <div className="name">
          <span className="document-icon">
            <AiOutlineFolder size="1.7em" color="#757575" />
          </span>
          <Tooltip title={truncateText(folder.name, toolTipMaxCharacters)}>
            <span className="document-name">{folder.name}</span>
          </Tooltip>
        </div>
      </div>
      <div className="DriveListContent-table-owner-column">
        {folder.creatorFullName ? (
          <Tooltip title={folder.creatorEmail}>
            <span>{folder.creatorFullName}</span>
          </Tooltip>
        ) : (
          <span>{folder.creatorEmail}</span>
        )}
      </div>
      <div className="DriveListContent-table-date-column">
        <Tooltip
          title={
            <DriveDateTooltip createdAt={folder.createdAt} updatedAt={folder.updatedAt} />
          }
        >
          <span>
            {localFormatDate(
              dayjs(folder.updatedAt ? folder.updatedAt : folder.createdAt),
            )}
          </span>
        </Tooltip>
      </div>
      <div className="DriveListContent-table-size-column">
        <AiOutlineMinus />
      </div>
      <div className="DriveListContent-table-actions-column">
        <div className="action-main-container">
          {folderRights && (
            <>
              <div className="action-custom-container">
                <div className="action-hover-container">
                  <div className="action-icons">
                    <div
                      className="action-rounded-icon"
                      onClick={(e) => setRenameFolder(folder)}
                    >
                      <AiOutlineEdit size="1.5em" />
                    </div>
                  </div>
                </div>
              </div>
              <Dropdown menu={{ items: folderItems }} trigger={['click']}>
                <a className="action-more-container">
                  <div className="action-more">
                    <BiDotsVerticalRounded size="1.5em" />
                  </div>
                </a>
              </Dropdown>
            </>
          )}
          <div className="action-more-mobile">
            <Button type="primary" onClick={() => fetchOrSelectFolder(folder)}>
              <AiOutlineEye size="1.5em" />
            </Button>
            {folderRights && (
              <>
                <Button onClick={(e) => setRenameFolder(folder)}>
                  <AiOutlineEdit size="1.5em" />
                </Button>
                <Button
                  className="btn-danger-border"
                  onClick={(e) => onDeleteFolder(folder)}
                >
                  <IoTrashOutline size="1.2em" className="error-color" />
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FolderRow
