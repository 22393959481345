import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FetchStatus } from '../../utils/FetchStatus'
import {
  PendingRoomInterface,
  RoomFile,
  RoomInterface,
  RoomParticipant,
  RoomParticipantRole,
} from '../room/RoomInterface'
import { logout } from '../auth/redux/authSlice'
import {
  fetchCreateRoom,
  fetchGenerateRoom,
  fetchPeriodicRoomById,
  fetchUpdatePeriodicRoom,
  fetchUpdatePlanRoom,
  fetchUpdateRoom,
} from '../../utils/MeetingUtils'
import customFetch from '../../customFetch'

export type RoomStatus = 'upcoming' | 'archived' | 'pending'
export type RoomManageTab = 'general' | 'participants' | 'documents'

export interface RoomToModify extends RoomInterface {
  unconfirmedFiles?: RoomFile[]
  unconfirmedFileDeletions?: string[]
  createFromPending?: boolean
  newStartDate?: string
}

export interface RoomToModifyPending extends RoomToModify {
  plannedDates: number[]
}

interface State {
  rooms: {
    [key in RoomStatus]: {
      rooms: RoomInterface[] | PendingRoomInterface[]
      fetchStatus: FetchStatus
      fetchError?: string
    }
  }
  periodicRooms: {
    [key in RoomStatus]: {
      rooms: RoomInterface[]
      fetchPeriodicRoomStatus: FetchStatus
      fetchPeriodicRoomError?: string
    }
  }
  roomToModify?: RoomToModify | RoomToModifyPending
  roomToModifyConfirm?: RoomToModify | RoomToModifyPending
  roomToDelete?: {
    room: RoomInterface | PendingRoomInterface | undefined
    pending: boolean
  }
  roomFetched?: RoomInterface
  roomManageTab: RoomManageTab
  createRoomStatus: FetchStatus
  generateRoomStatus: FetchStatus
  fetchPeriodicRoomStatus: FetchStatus
  periodicRoomFetched?: RoomToModify
  updateRoomStatus: string
  updatePeriodicRoomStatus: FetchStatus
  updatePlanRoomStatus: FetchStatus
  archiveRoomStatus: string
  deleteRoomStatus: FetchStatus
  deletePeriodicRoomStatus: FetchStatus
  deleteGeneratedRoomStatus: FetchStatus
  notifyMissingParticipantsStatus: FetchStatus
  roomsCount?: {
    upcoming: number
    pending: number
  }
  roomsCountStatus: FetchStatus
  periodicUpcomingCount?: number
  periodicUpcomingCountStatus: FetchStatus
}

export const initialState: State = {
  rooms: {
    upcoming: {
      rooms: [],
      fetchStatus: 'idle',
    },
    archived: {
      rooms: [],
      fetchStatus: 'idle',
    },
    pending: {
      rooms: [],
      fetchStatus: 'idle',
    },
  },
  periodicRooms: {
    upcoming: {
      rooms: [],
      fetchPeriodicRoomStatus: 'idle',
    },
    archived: {
      rooms: [],
      fetchPeriodicRoomStatus: 'idle',
    },
    pending: {
      rooms: [],
      fetchPeriodicRoomStatus: 'idle',
    },
  },
  roomManageTab: 'general',
  createRoomStatus: 'idle',
  generateRoomStatus: 'idle',
  fetchPeriodicRoomStatus: 'idle',
  updateRoomStatus: 'idle',
  updatePeriodicRoomStatus: 'idle',
  updatePlanRoomStatus: 'idle',
  archiveRoomStatus: 'idle',
  deleteRoomStatus: 'idle',
  deletePeriodicRoomStatus: 'idle',
  deleteGeneratedRoomStatus: 'idle',
  notifyMissingParticipantsStatus: 'idle',
  roomsCountStatus: 'idle',
  periodicUpcomingCountStatus: 'idle',
}

/**
 * Fetch rooms for current user.
 */
export const fetchRooms = createAsyncThunk(
  'rooms/fetchRoomsStatus',
  async (
    {
      status,
      minDate,
      maxDate,
    }: { status: RoomStatus; minDate?: string; maxDate?: string },
    { getState, dispatch },
  ) => {
    const { auth } = getState() as { auth: { jwt: string } }
    const response = await customFetch(
      `${process.env.REACT_APP_MEDIA_BASE_URL}/room/list/${status}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${auth.jwt}`,
        },
        body: JSON.stringify({
          minDate,
          maxDate,
        }),
      },
    )

    if (!response.ok) {
      const status = response.status
      if (status === 401) {
        dispatch(logout())
      }
      throw new Error(response.status.toFixed())
    }

    return {
      status,
      rooms: await response.json(),
    }
  },
)

/**
 * Fetch rooms for current user.
 */
export const fetchPeriodicRooms = createAsyncThunk(
  'rooms/fetchPeriodicRoomsStatus',
  async (
    {
      status,
      minDate,
      maxDate,
    }: { status: RoomStatus; minDate?: string; maxDate?: string },
    { getState, dispatch },
  ) => {
    const { auth } = getState() as { auth: { jwt: string } }
    const response = await fetch(
      `${process.env.REACT_APP_MEDIA_BASE_URL}/periodicRoom/list/${status}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${auth.jwt}`,
        },
        body: JSON.stringify({
          minDate,
          maxDate,
        }),
      },
    )

    if (!response.ok) {
      const status = response.status
      if (status === 401) {
        dispatch(logout())
      }
      throw new Error(response.status.toFixed())
    }

    return {
      status,
      rooms: await response.json(),
    }
  },
)

/**
 * Create room.
 */
export const createRoom = createAsyncThunk(
  'rooms/createRoomStatus',
  async (payload: undefined, { getState, dispatch }) => {
    const { auth } = getState() as { auth: { jwt: string } }
    const room = await fetchCreateRoom(auth.jwt)
    dispatch(setRoomToModify(room))
  },
)

/**
 * Create room.
 */
export const updateRoom = createAsyncThunk(
  'rooms/updateRoomStatus',
  async (
    {
      id,
      name,
      color,
      type,
      reference,
      startDate,
      duration,
      creatorNote,
      participants,
      invited,
      unconfirmedFiles,
      unconfirmedFileDeletions,
      createFromPending,
      periodicity,
    }: RoomToModify,
    { getState },
  ) => {
    const { auth } = getState() as { auth: { jwt: string } }
    await fetchUpdateRoom(auth.jwt, {
      id,
      name,
      color,
      type,
      reference,
      startDate,
      duration,
      creatorNote,
      participants,
      invited,
      unconfirmedFiles,
      unconfirmedFileDeletions,
      createFromPending,
      periodicity,
    })
  },
)

/**
 * update periodic room.
 */
export const updatePeriodicRoom = createAsyncThunk(
  'rooms/updatePeriodicRoomStatus',
  async (
    {
      id,
      name,
      color,
      type,
      reference,
      startDate,
      duration,
      creatorNote,
      participants,
      invited,
      unconfirmedFiles,
      unconfirmedFileDeletions,
      periodicity,
    }: RoomToModify,
    { getState },
  ) => {
    const { auth } = getState() as { auth: { jwt: string } }
    await fetchUpdatePeriodicRoom(auth.jwt, {
      id,
      name,
      color,
      type,
      reference,
      startDate,
      duration,
      creatorNote,
      participants,
      invited,
      unconfirmedFiles,
      unconfirmedFileDeletions,
      periodicity,
    })
  },
)

/**
 * Generate room from periodicRoom
 */
export const generateRoom = createAsyncThunk(
  'rooms/generateRoomStatus',
  async ({ startDate, periodicRoomId }: RoomInterface, { getState }) => {
    const { auth } = getState() as { auth: { jwt: string } }
    return await fetchGenerateRoom(auth.jwt, {
      startDate,
      periodicRoomId,
    })
  },
)

/**
 * Get a specific periodicRoom
 */
export const fetchPeriodicRoom = createAsyncThunk(
  'rooms/fetchPeriodicRoomStatus',
  async ({ pid }: { pid: string }, { getState }) => {
    const { auth } = getState() as { auth: { jwt: string } }
    return await fetchPeriodicRoomById(auth.jwt, pid)
  },
)

/**
 * Update room with planner.
 */
export const updatePlanRoom = createAsyncThunk(
  'rooms/updatePlanRoomStatus',
  async (
    {
      room: {
        id,
        name,
        color,
        type,
        reference,
        duration,
        creatorNote,
        participants,
        invited,
        unconfirmedFiles,
        unconfirmedFileDeletions,
      },
      availableDates,
    }: { room: RoomToModifyPending; availableDates: number[] },
    { getState },
  ) => {
    const { auth } = getState() as { auth: { jwt: string } }
    await fetchUpdatePlanRoom(
      auth.jwt,
      {
        id,
        name,
        color,
        type,
        reference,
        duration,
        creatorNote,
        participants,
        invited,
        unconfirmedFiles,
        unconfirmedFileDeletions,
      },
      availableDates.map((date) => new Date(date).toISOString()),
    )
  },
)

/**
 * Delete room.
 */
export const deleteRoom = createAsyncThunk(
  'rooms/deleteRoomStatus',
  async (payload: { roomId: string; pending: boolean }, { getState }) => {
    const { auth } = getState() as { auth: { jwt: string } }

    let response
    if (payload.pending) {
      response = await fetch(process.env.REACT_APP_MEDIA_BASE_URL + '/room/plan', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${auth.jwt}`,
        },
        body: JSON.stringify(payload),
      })
    } else {
      response = await fetch(process.env.REACT_APP_MEDIA_BASE_URL + '/room', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${auth.jwt}`,
        },
        body: JSON.stringify({
          roomId: payload.roomId,
        }),
      })
    }

    if (!response.ok) {
      throw new Error('Could not delete room')
    }
  },
)

/**
 * Delete PeriodicRoom.
 */
export const deletePeriodicRoom = createAsyncThunk(
  'rooms/deletePeriodicRoomStatus',
  async (payload: { roomId: string }, { getState }) => {
    const { auth } = getState() as { auth: { jwt: string } }

    const response = await fetch(
      process.env.REACT_APP_MEDIA_BASE_URL + '/periodicRoom/global/upcoming',
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${auth.jwt}`,
        },
        body: JSON.stringify({
          roomId: payload.roomId,
        }),
      },
    )

    if (!response.ok) {
      throw new Error('Could not delete room')
    }
  },
)

/**
 * Delete generated room.
 */
export const deleteGeneratedRoom = createAsyncThunk(
  'rooms/deleteGeneratedRoomStatus',
  async (payload: { periodicRoomId: string; date: string; id: string }, { getState }) => {
    const { auth } = getState() as { auth: { jwt: string } }

    let response
    response = await fetch(process.env.REACT_APP_MEDIA_BASE_URL + '/periodicRoom/', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${auth.jwt}`,
      },
      body: JSON.stringify({
        periodicRoomId: payload.periodicRoomId,
        date: payload.date,
        id: payload.id,
      }),
    })

    if (!response.ok) {
      throw new Error('Could not delete room')
    }
  },
)

/**
 * Archive room.
 */
export const archiveRoom = createAsyncThunk(
  'rooms/archiveRoomStatus',
  async (payload: { roomId: string }, { getState }) => {
    const { auth } = getState() as { auth: { jwt: string } }

    const response = await fetch(process.env.REACT_APP_MEDIA_BASE_URL + '/room/archive', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${auth.jwt}`,
      },
      body: JSON.stringify(payload),
    })

    if (!response.ok) {
      throw new Error('Could not archive room')
    }
  },
)

/**
 * Create room.
 */
export const deleteRoomInvited = createAsyncThunk(
  'rooms/deleteInvitedStatus',
  async (payload: { invited: string; roomId: string }, { getState }) => {
    const { auth } = getState() as { auth: { jwt: string } }

    const response = await fetch(process.env.REACT_APP_MEDIA_BASE_URL + '/room/invited', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${auth.jwt}`,
      },
      body: JSON.stringify(payload),
    })

    if (!response.ok) {
      if (Math.floor(response.status / 100) === 4) {
        const body = await response.json()

        throw new Error(body.reason)
      }

      throw new Error('Server error occured')
    }

    const body = await response.json()

    return {
      roomId: payload.roomId,
      invited: body.invited,
    }
  },
)

/**
 * Notify missing participants (from the participants drawer)
 */
export const notifyMissingParticipants = createAsyncThunk(
  'rooms/notifyMissingParticipantsStatus',
  async (payload: { roomId: string }, { getState }) => {
    const { auth } = getState() as { auth: { jwt: string } }

    const response = await fetch(
      process.env.REACT_APP_MEDIA_BASE_URL + '/room/notify-missing-participants',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${auth.jwt}`,
        },
        body: JSON.stringify(payload),
      },
    )

    if (!response.ok) {
      throw new Error('Could not notify participants')
    }
  },
)

export const roomsCount = createAsyncThunk(
  'rooms/roomsCountStatus',
  async (payload: {}, { getState }) => {
    const { auth } = getState() as { auth: { jwt: string } }

    const response = await fetch(process.env.REACT_APP_MEDIA_BASE_URL + '/room/count', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${auth.jwt}`,
      },
    })

    if (!response.ok) {
      throw new Error('Could not count rooms')
    }

    const body = await response.json()

    return body
  },
)

/**
 * Count the number of periodic room
 */
export const periodicRoomsCount = createAsyncThunk(
  'rooms/periodicRoomsCountStatus',
  async ({ minDate, maxDate }: { minDate?: string; maxDate?: string }, { getState }) => {
    const { auth } = getState() as { auth: { jwt: string } }

    const response = await fetch(
      process.env.REACT_APP_MEDIA_BASE_URL + '/periodicRoom/count',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${auth.jwt}`,
        },
        body: JSON.stringify({
          minDate,
          maxDate,
        }),
      },
    )

    if (!response.ok) {
      throw new Error('Could not count periodic rooms')
    }

    const body = await response.json()

    return body
  },
)

export const isRoomToModifyPending = (room: any): room is RoomToModifyPending => {
  return room && (room as RoomToModifyPending).plannedDates !== undefined
}

const roomsSlice = createSlice({
  name: 'rooms',
  initialState,
  reducers: {
    setRoomToModify: (
      state,
      { payload }: PayloadAction<RoomToModify | RoomToModifyPending | undefined>,
    ) => {
      state.roomToModify = payload
      state.roomManageTab = 'general'
      if (!payload) {
        state.roomToModifyConfirm = undefined
      }
    },
    setRoomToModifyConfirm: (
      state,
      { payload }: PayloadAction<RoomToModify | RoomToModifyPending | undefined>,
    ) => {
      state.roomToModifyConfirm = payload
    },
    addDateRoomToModify: (state, { payload }: PayloadAction<string>) => {
      if (isRoomToModifyPending(state.roomToModify) && state.roomToModify) {
        if (!state.roomToModify.plannedDates) {
          state.roomToModify.plannedDates = []
        }
        const element = new Date(payload)
        state.roomToModify.plannedDates.push(element.getTime())
      }
    },
    deleteDateRoomToModify(state, { payload }: PayloadAction<number>) {
      if (
        isRoomToModifyPending(state.roomToModify) &&
        state.roomToModify &&
        state.roomToModify.plannedDates
      ) {
        state.roomToModify.plannedDates = state.roomToModify.plannedDates.filter(
          (d) => d !== payload,
        )
      }
    },
    setRoomToDelete: (
      state,
      {
        payload,
      }: PayloadAction<{
        room: RoomInterface | PendingRoomInterface | undefined
        pending: boolean
      }>,
    ) => {
      state.roomToDelete = {
        pending: payload.pending || false,
        room: payload.room,
      }
    },
    addParticipant: (state, { payload }: PayloadAction<RoomParticipant>) => {
      if (state.roomToModify) {
        if (!state.roomToModify.participants) {
          state.roomToModify.participants = []
        }

        const email = payload.email.trim().toLowerCase()
        const existingIndex = state.roomToModify.participants.findIndex(
          (p) => p.email === email,
        )
        if (existingIndex >= 0) {
          state.roomToModify.participants.splice(existingIndex, 1, payload)
        } else {
          state.roomToModify.participants.push(payload)
        }
      }
    },
    deleteParticipant: (state, { payload }: PayloadAction<string>) => {
      if (state.roomToModify) {
        if (!state.roomToModify.participants) {
          state.roomToModify.participants = []
        }
        state.roomToModify.participants = state.roomToModify.participants.filter(
          (p: RoomParticipant) => p.email != payload,
        )
      }
    },
    updateParticipantRole: (state, { payload }: PayloadAction<string>) => {
      if (state.roomToModify?.participants) {
        const [participantMail, newRole] = payload.split(' ')
        const index = state.roomToModify.participants.findIndex(
          (participant) => participant.email === participantMail,
        )
        state.roomToModify.participants[index].role = newRole as RoomParticipantRole
      }
    },
    addInvited: (state, { payload }: PayloadAction<RoomParticipant>) => {
      if (state.roomToModify) {
        if (!state.roomToModify.invited) {
          state.roomToModify.invited = []
        }

        const email = payload.email.trim().toLowerCase()
        const role = payload.role

        const existingIndex = state.roomToModify.invited.findIndex(
          (p) => p.email === email,
        )
        if (existingIndex >= 0) {
          state.roomToModify.invited.splice(existingIndex, 1, { email, role })
        } else {
          state.roomToModify.invited.push({ email, role })
        }
      }
    },
    deleteInvited: (state, { payload }: PayloadAction<string>) => {
      if (state.roomToModify) {
        if (!state.roomToModify.invited) {
          state.roomToModify.invited = []
        }
        state.roomToModify.invited = state.roomToModify.invited.filter(
          (p: RoomParticipant) => p.email != payload,
        )
      }
    },
    updateInvitedRole: (state, { payload }: PayloadAction<string>) => {
      if (state.roomToModify?.invited) {
        const [invitedMail, newRole] = payload.split(' ')
        const index = state.roomToModify.invited.findIndex(
          (invited) => invited.email === invitedMail,
        )
        state.roomToModify.invited[index].role = newRole as RoomParticipantRole
      }
    },
    setRoomManageTab: (state, { payload }: PayloadAction<RoomManageTab>) => {
      state.roomManageTab = payload
    },
    idleCreateRoomStatus: (state) => {
      state.createRoomStatus = 'idle'
    },
    idleGenerateRoomStatus: (state) => {
      state.generateRoomStatus = 'idle'
    },
    idleFetchPeriodicRoomStatus: (state) => {
      state.fetchPeriodicRoomStatus = 'idle'
    },
    idleUpdateRoomStatus: (state) => {
      state.updateRoomStatus = 'idle'
    },
    idleupdatePeriodicRoomStatus: (state) => {
      state.updatePeriodicRoomStatus = 'idle'
    },
    idleUpdatePlanRoomStatus: (state) => {
      state.updatePlanRoomStatus = 'idle'
    },
    idleArchiveRoomStatus: (state) => {
      state.archiveRoomStatus = 'idle'
    },
    idleDeleteRoomStatus: (state) => {
      state.deleteRoomStatus = 'idle'
    },
    idleDeleteGeneratedRoomStatus: (state) => {
      state.deleteGeneratedRoomStatus = 'idle'
    },
    idleDeletePeriodicRoomStatus: (state) => {
      state.deletePeriodicRoomStatus = 'idle'
    },
    idleNotifyMissingParticipantsStatus: (state) => {
      state.notifyMissingParticipantsStatus = 'idle'
    },
    idleRoomsCountStatus: (state) => {
      state.roomsCountStatus = 'idle'
    },
    idlePeriodicRoomsCountStatus: (state) => {
      state.periodicUpcomingCountStatus = 'idle'
    },
    updateFiles: (
      state,
      { payload }: PayloadAction<{ roomId: string; files: RoomFile[] }>,
    ) => {
      if (state.roomToModify?.id === payload.roomId) {
        state.roomToModify.files = payload.files
      }

      let index = state.rooms.upcoming.rooms.findIndex(
        (room) => room.id === payload.roomId,
      )
      if (index >= 0) {
        state.rooms.upcoming.rooms[index].files = payload.files
      }

      index = state.rooms.archived.rooms.findIndex((room) => room.id === payload.roomId)
      if (index >= 0) {
        state.rooms.archived.rooms[index].files = payload.files
      }
    },
    addUnconfirmedFile: (
      state,
      { payload }: PayloadAction<{ roomId: string; file: RoomFile }>,
    ) => {
      if (state.roomToModify?.id === payload.roomId) {
        if (!state.roomToModify.unconfirmedFiles) {
          state.roomToModify.unconfirmedFiles = []
        }
        state.roomToModify.unconfirmedFiles.push(payload.file)
      }
    },
    removeUnconfirmedFile: (
      state,
      { payload }: PayloadAction<{ roomId: string; fileUid: string }>,
    ) => {
      if (state.roomToModify?.id === payload.roomId) {
        if (!state.roomToModify.unconfirmedFileDeletions) {
          state.roomToModify.unconfirmedFileDeletions = []
        }
        state.roomToModify.unconfirmedFileDeletions?.push(payload.fileUid)
      }
    },
    setPeriodicRooms: (
      state,
      { payload }: PayloadAction<{ status: RoomStatus; rooms: RoomInterface[] }>,
    ) => {
      state.periodicRooms[payload.status].rooms = payload.rooms
    },
  },

  extraReducers: {
    [fetchRooms.pending.type]: (state, action) => {
      state.rooms[action.meta.arg.status as RoomStatus] = {
        rooms: [],
        fetchStatus: 'loading',
      }
    },
    [fetchRooms.fulfilled.type]: (
      state,
      action: PayloadAction<{
        status: RoomStatus
        rooms: RoomInterface[] | RoomInterface[]
      }>,
    ) => {
      state.rooms[action.payload.status] = {
        rooms: action.payload.rooms,
        fetchStatus: 'success',
      }
    },
    [fetchRooms.rejected.type]: (state, action) => {
      state.rooms[action.meta.arg.status as RoomStatus] = {
        rooms: [],
        fetchStatus: 'error',
        fetchError: action.error.message,
      }
    },

    [fetchPeriodicRooms.pending.type]: (state, action) => {
      state.periodicRooms[action.meta.arg.status as RoomStatus] = {
        rooms: [],
        fetchPeriodicRoomStatus: 'loading',
      }
    },
    [fetchPeriodicRooms.fulfilled.type]: (
      state,
      action: PayloadAction<{
        status: RoomStatus
        rooms: RoomInterface[] | RoomInterface[]
      }>,
    ) => {
      state.periodicRooms[action.payload.status] = {
        rooms: action.payload.rooms,
        fetchPeriodicRoomStatus: 'success',
      }
    },
    [fetchPeriodicRooms.rejected.type]: (state, action) => {
      state.periodicRooms[action.meta.arg.status as RoomStatus] = {
        rooms: [],
        fetchPeriodicRoomStatus: 'error',
        fetchPeriodicRoomError: action.error.message,
      }
    },

    [createRoom.pending.type]: (state, action) => {
      state.createRoomStatus = 'loading'
    },
    [createRoom.fulfilled.type]: (state, action) => {
      state.createRoomStatus = 'success'
    },
    [createRoom.rejected.type]: (state, action) => {
      state.createRoomStatus = 'error'
    },

    [generateRoom.pending.type]: (state, action) => {
      state.generateRoomStatus = 'loading'
    },
    [generateRoom.fulfilled.type]: (state, action) => {
      state.roomFetched = action.payload
      state.generateRoomStatus = 'success'
    },
    [generateRoom.rejected.type]: (state, action) => {
      state.generateRoomStatus = 'error'
    },

    [fetchPeriodicRoom.pending.type]: (state, action) => {
      state.fetchPeriodicRoomStatus = 'loading'
    },
    [fetchPeriodicRoom.fulfilled.type]: (state, action) => {
      state.periodicRoomFetched = action.payload
      state.fetchPeriodicRoomStatus = 'success'
    },
    [fetchPeriodicRoom.rejected.type]: (state, action) => {
      state.fetchPeriodicRoomStatus = 'error'
    },

    [updateRoom.pending.type]: (state, action) => {
      state.updateRoomStatus = 'loading'
    },
    [updateRoom.fulfilled.type]: (state, action) => {
      state.updateRoomStatus = 'success'
    },
    [updateRoom.rejected.type]: (state, action) => {
      state.updateRoomStatus = 'error'
    },

    [updatePeriodicRoom.pending.type]: (state, action) => {
      state.updatePeriodicRoomStatus = 'loading'
    },
    [updatePeriodicRoom.fulfilled.type]: (state, action) => {
      state.updatePeriodicRoomStatus = 'success'
    },
    [updatePeriodicRoom.rejected.type]: (state, action) => {
      state.updatePeriodicRoomStatus = 'error'
    },

    [updatePlanRoom.pending.type]: (state, action) => {
      state.updatePlanRoomStatus = 'loading'
    },
    [updatePlanRoom.fulfilled.type]: (state, action) => {
      state.updatePlanRoomStatus = 'success'
    },
    [updatePlanRoom.rejected.type]: (state, action) => {
      state.updatePlanRoomStatus = 'error'
    },

    [deleteRoom.pending.type]: (state, action) => {
      state.deleteRoomStatus = 'loading'
    },
    [deleteRoom.fulfilled.type]: (state, action) => {
      state.deleteRoomStatus = 'success'
    },
    [deleteRoom.rejected.type]: (state, action) => {
      state.deleteRoomStatus = 'error'
    },

    [deleteGeneratedRoom.pending.type]: (state, action) => {
      state.deleteGeneratedRoomStatus = 'loading'
    },
    [deleteGeneratedRoom.fulfilled.type]: (state, action) => {
      state.deleteGeneratedRoomStatus = 'success'
    },
    [deleteGeneratedRoom.rejected.type]: (state, action) => {
      state.deleteGeneratedRoomStatus = 'error'
    },

    [deletePeriodicRoom.pending.type]: (state, action) => {
      state.deletePeriodicRoomStatus = 'loading'
    },
    [deletePeriodicRoom.fulfilled.type]: (state, action) => {
      state.deletePeriodicRoomStatus = 'success'
    },
    [deletePeriodicRoom.rejected.type]: (state, action) => {
      state.deletePeriodicRoomStatus = 'error'
    },

    [archiveRoom.pending.type]: (state, action) => {
      state.archiveRoomStatus = 'loading'
    },
    [archiveRoom.fulfilled.type]: (state, action) => {
      state.archiveRoomStatus = 'success'
    },
    [archiveRoom.rejected.type]: (state, action) => {
      state.archiveRoomStatus = 'error'
    },
    [notifyMissingParticipants.pending.type]: (state, action) => {
      state.notifyMissingParticipantsStatus = 'loading'
    },
    [notifyMissingParticipants.fulfilled.type]: (state, action) => {
      state.notifyMissingParticipantsStatus = 'success'
    },
    [notifyMissingParticipants.rejected.type]: (state, action) => {
      state.notifyMissingParticipantsStatus = 'error'
    },

    [roomsCount.pending.type]: (state, action) => {
      state.roomsCountStatus = 'loading'
    },
    [roomsCount.fulfilled.type]: (
      state,
      { payload }: PayloadAction<{ upcoming: number; pending: number }>,
    ) => {
      state.roomsCountStatus = 'success'
      state.roomsCount = { ...payload }
    },
    [roomsCount.rejected.type]: (state, action) => {
      state.roomsCountStatus = 'error'
    },
    [periodicRoomsCount.pending.type]: (state, action) => {
      state.periodicUpcomingCountStatus = 'loading'
    },
    [periodicRoomsCount.fulfilled.type]: (
      state,
      { payload }: PayloadAction<{ periodicUpcoming: number }>,
    ) => {
      state.periodicUpcomingCountStatus = 'success'
      state.periodicUpcomingCount = payload.periodicUpcoming
    },
    [periodicRoomsCount.rejected.type]: (state, action) => {
      state.periodicUpcomingCountStatus = 'error'
    },
  },
})

export default roomsSlice.reducer

export const {
  setRoomToModify,
  setRoomToModifyConfirm,
  setRoomToDelete,
  deleteDateRoomToModify,
  addDateRoomToModify,
  addParticipant,
  deleteParticipant,
  updateParticipantRole,
  addInvited,
  deleteInvited,
  updateInvitedRole,
  setRoomManageTab,
  idleCreateRoomStatus,
  idleGenerateRoomStatus,
  idleUpdateRoomStatus,
  idleFetchPeriodicRoomStatus,
  idleupdatePeriodicRoomStatus,
  idleUpdatePlanRoomStatus,
  idleDeleteRoomStatus,
  idleDeleteGeneratedRoomStatus,
  idleDeletePeriodicRoomStatus,
  idleArchiveRoomStatus,
  idleNotifyMissingParticipantsStatus,
  updateFiles,
  addUnconfirmedFile,
  removeUnconfirmedFile,
  idleRoomsCountStatus,
  idlePeriodicRoomsCountStatus,
  setPeriodicRooms,
} = roomsSlice.actions
