import React, { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import {
  DriveInterface,
  setAdminRight,
  setDriveToModify,
} from '../../features/Drives/redux/drivesSlice'
import { isDriveAdmin } from './DrivesService'
import { Tooltip } from 'antd'
import { AiOutlineUser, AiOutlineUsergroupAdd } from 'react-icons/ai'

interface Props {
  driveId: string
}

function DriveRightsIcon({ driveId }: Props) {
  const { t } = useTranslation('drives')
  const dispatch = useDispatch<AppDispatch>()
  const email = useSelector((state: RootState) => state.auth.email)
  const groups = useSelector((state: RootState) => state.auth.groups)
  const drives = useSelector((state: RootState) => state.drive.drives)

  const drive = drives?.find((drive) => drive.id === driveId)

  /**
   *
   * @param e
   * @param drive
   */
  function showDrive(e: MouseEvent, drive?: DriveInterface) {
    if (drive) {
      dispatch(setAdminRight(isDriveAdmin(drive, email, groups)))
      dispatch(setDriveToModify({ drive }))
      e.stopPropagation()
    }
  }

  const isAdmin = isDriveAdmin(drive, email, groups)

  if (isAdmin) {
    return (
      <Tooltip title={t('Manage access rights')}>
        <AiOutlineUsergroupAdd size="1.3em" onClick={(e) => showDrive(e, drive)} />
      </Tooltip>
    )
  } else {
    return (
      <Tooltip title={t('View access rights')}>
        <AiOutlineUser size="1.2em" onClick={(e) => showDrive(e, drive)} />
      </Tooltip>
    )
  }
}

export default DriveRightsIcon
