import React, { useState } from 'react'
import { AutoComplete, Button, Col, Form, Row, Select, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import { useTranslation } from 'react-i18next'
import {
  RoomInterface,
  RoomParticipant,
  RoomParticipantRole,
  RoomParticipantRoleEnum,
} from '../../../features/room/RoomInterface'
import './AddParticipantForm.scss'
import { attemptAddParticipant } from '../../../features/room/redux/roomSlice'
import { IoIosAdd } from 'react-icons/io'
import { BiInfoCircle } from 'react-icons/bi'
import '../../../index.scss'
import { meetingColor } from '../RoomManage'
import { searchUsersAndInvites } from '../../../features/Organisation/OrganisationService'
import {
  SelectOption,
  Option,
} from '../../DrivesComponent/CreateDrive/DriveFormAddParticipant'
import { IoTrashOutline } from 'react-icons/io5'
import { removeInvited } from '../../../features/invite/redux/inviteSlice'
import { useToastContext } from '../../Toast/ToastContext'
interface AddParticipantFormProps {
  room?: RoomInterface
  vertical: boolean
  addParticipant: (payload: RoomParticipant) => any
  addInvited: (values: any) => any
}

interface KnownParticipants {
  [email: string]: { firstName: string; lastName: string }
}

function AddParticipantForm({
  room,
  vertical,
  addParticipant,
  addInvited,
}: AddParticipantFormProps) {
  const { t } = useTranslation('meetings')
  const jwt = useSelector((state: RootState) => state.auth.jwt) || ''
  const email = useSelector((state: RootState) => state.auth.email) || ''
  const organizationId = useSelector(
    (state: RootState) => state.organisation.organisation?.id,
  )
  const dispatch = useDispatch<AppDispatch>()
  const { ToastOpen } = useToastContext()
  const [addParticipantForm] = Form.useForm()
  const [knownPeers, dispatchKnownPeers] = useState<KnownParticipants>({})

  const onAddParticipant = (values: {
    newParticipant: string
    role: RoomParticipantRole
  }) => {
    const newParticipant = values.newParticipant.trim().toLowerCase()

    if (newParticipant === room?.creator?.email) {
      return
    }

    const alreadyAdded =
      room?.participants?.some((participant) => participant.email === newParticipant) ||
      room?.invited?.some((invited) => invited.email === newParticipant)

    if (alreadyAdded) {
      ToastOpen({
        message: t('This participant has already been added to the meeting'),
        type: 'error',
      })
      return
    }

    if (knownPeers[newParticipant]) {
      dispatch(
        addParticipant({
          email: newParticipant,
          firstName: knownPeers[newParticipant].firstName,
          lastName: knownPeers[newParticipant].lastName,
          role: values.role,
          color: meetingColor[0].value,
        }),
      )
    } else {
      dispatch(
        addInvited({
          email: newParticipant,
          role: values.role,
        }),
      )
    }

    dispatch(
      attemptAddParticipant({
        email: newParticipant,
        role: values.role,
        color: meetingColor[0].value,
      }),
    )
    addParticipantForm.resetFields(['role', 'newParticipant'])

    const updatedOptions = options.filter((option) =>
      option.options.filter((opt) => opt.value !== newParticipant),
    )

    setOptions(updatedOptions)
  }

  const [options, setOptions] = useState<Option[]>([])
  const renderTitle = (title: string) => <span>{title}</span>

  function deleteInvited(email: string) {
    dispatch(removeInvited({ jwt, service: 'meeting', email }))
    setTimeout(() => {
      addParticipantForm.resetFields(['newParticipant'])
    }, 10)
  }
  const renderInviteItem = (email: string) => (
    <span className="d-flex d-flex-between">
      <Tooltip title={email}>
        <div className="room-participant-item-body-email-max">{email}</div>
      </Tooltip>
      <Button className="btn-danger-border" onClick={() => deleteInvited(email)}>
        <IoTrashOutline className="error-color" />
      </Button>
    </span>
  )

  const onSelect = (data: string, option: any) => {
    setOptions([])
  }

  const onSearch = async (searchText: string) => {
    if (organizationId) {
      setOptions([])

      try {
        const usersAndInvites = await searchUsersAndInvites(
          jwt,
          searchText,
          organizationId,
          'meeting',
          [
            ...(room?.participants?.map((p) => p.email) || []),
            ...(room?.invited?.map((i) => i.email) || []),
            email,
          ],
        )

        const usersSelectOptions: SelectOption[] = usersAndInvites.users.map(
          ({ email, firstName, lastName }) => ({
            id: email,
            label: `${firstName} ${lastName} (${email})`,
            value: email,
            type: 'user',
            firstName,
            lastName,
          }),
        )

        const inviteSelectOptions: SelectOption[] = usersAndInvites.invites.map((i) => {
          return {
            id: i.email,
            label: renderInviteItem(i.email),
            value: i.email,
            type: 'invite',
          }
        })

        dispatchKnownPeers({
          ...knownPeers,
          ...usersSelectOptions.reduce((collector, user) => {
            if (user.firstName && user.lastName) {
              collector[user.value] = {
                firstName: user.firstName,
                lastName: user.lastName,
              }
            }
            return collector
          }, {} as KnownParticipants),
        })

        const optionsFull: Option[] = [
          {
            label: renderTitle(t('Users', { ns: 'layout' })),
            options: usersSelectOptions,
          },
          {
            label: renderTitle(t('Guests', { ns: 'layout' })),
            options: inviteSelectOptions,
          },
        ]
        setOptions(optionsFull)
      } catch (e) {
        console.error(e)
      }
    }
  }

  const participantFormElement = (
    <Form.Item
      style={{ marginBottom: '0px' }}
      name="newParticipant"
      rules={[
        {
          required: true,
          message: t('Please input participant name'),
        },
        {
          type: 'email',
          message: t('The format of the email does not comply', { ns: 'common' }),
          validateTrigger: 'onSubmit',
          transform: (value) => value.trim(),
        },
      ]}
    >
      <AutoComplete
        allowClear
        options={options}
        onSelect={onSelect}
        onSearch={onSearch}
        placeholder={t('Search participant')}
      />
    </Form.Item>
  )

  const roleFormElement = (
    <Form.Item
      style={{ marginBottom: '0px' }}
      name="role"
      rules={[{ required: true, message: t('Choose a role please') }]}
    >
      <Select defaultValue={{ key: RoomParticipantRoleEnum.Attendee }}>
        <Select.Option value={RoomParticipantRoleEnum.Attendee}>
          <div className="d-flex d-flex-between d-flex-middle">
            <span className="mr-05rem">{t('Attendee')}</span>
            <Tooltip
              title={t('No access to documents', { ns: 'common' })}
              className="cursor-pointer"
            >
              <BiInfoCircle size="1em" className="info-color" />
            </Tooltip>
          </div>
        </Select.Option>
        <Select.Option value={RoomParticipantRoleEnum.Document}>
          <div className="d-flex d-flex-between d-flex-middle">
            <span className="mr-05rem">{t('Document')}</span>
            <Tooltip
              title={t('Access to documents during the meeting', { ns: 'common' })}
              className="cursor-pointer"
            >
              <BiInfoCircle size="1em" className="info-color" />
            </Tooltip>
          </div>
        </Select.Option>
        <Select.Option value={RoomParticipantRoleEnum.Proofreader}>
          <div className="d-flex d-flex-between d-flex-middle">
            <span className="mr-05rem">{t('Proofreader')}</span>
            <Tooltip
              title={t('Access to documents before, during and after the meeting', {
                ns: 'common',
              })}
              className="cursor-pointer"
            >
              <BiInfoCircle size="1em" className="info-color" />
            </Tooltip>
          </div>
        </Select.Option>
      </Select>
    </Form.Item>
  )

  const submitFormElement = (
    <Form.Item style={{ marginBottom: '0' }}>
      <Button
        className={vertical ? 'full-width-btn' : ''}
        type="primary"
        icon={<IoIosAdd size="1.5rem" />}
        htmlType="submit"
      >
        <span className="hideOnMobile">{t('Add')}</span>
      </Button>
    </Form.Item>
  )

  return (
    <Form
      form={addParticipantForm}
      initialValues={{
        role: RoomParticipantRoleEnum.Attendee,
        color: meetingColor[0].value,
      }}
      name="basic"
      onFinish={onAddParticipant}
      layout="vertical"
      autoComplete="off"
      disabled={room?.archived}
      scrollToFirstError={true}
    >
      {vertical ? (
        <>
          {participantFormElement}
          <div className="mt-1rem">{roleFormElement}</div>
          <div className="mt-1rem">{submitFormElement}</div>
        </>
      ) : (
        <Row className="addParticipant">
          <Col span={24}>
            <i>{t('Search and choose participants to invite')}</i>
          </Col>
          <Col span={10} className="search-users">
            {participantFormElement}
          </Col>
          <Col span={10} className="role-users">
            {roleFormElement}
          </Col>
          <Col span={4} className="add-users">
            {submitFormElement}
          </Col>
        </Row>
      )}
    </Form>
  )
}

export default AddParticipantForm
